import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactDOMServer from "react-dom/server";
import {
  Widget,
  addUserMessage,
  toggleMsgLoader,
  toggleInputDisabled,
  renderCustomComponent,
  deleteMessages,
} from "@falkor/react-chat-widget";
import parse from "html-react-parser";
import { customAlphabet } from "nanoid";
import moment from "moment";
// import { toast } from "react-toastify";
import "@falkor/react-chat-widget/lib/styles.css";
import logo from "./logo.svg";
import { createParser } from "eventsource-parser";
import axios from "axios";
// import { franc } from "franc";

const alphabet = "0123456789abcdefghijklmnopqrstuvwxyz";
const nanoid = customAlphabet(alphabet, 10);

const API_URL = process.env.REACT_APP_API_URL;
const ENGINE_URL = process.env.REACT_APP_ENGINE_URL;
const SPEAKER = process.env.REACT_APP_SPEAKER;
const SPEAKER_MUTE = process.env.REACT_APP_SPEAKER_MUTE;
const BUDIBASE_TOKEN = process.env.REACT_APP_BUDIBASE_TOKEN;
const WEBCHAT_TOKEN = process.env.REACT_APP_WEBCHAT_TOKEN;
const CITIBOT_LANDING_PAGE_URL = process.env.REACT_APP_CITIBOT_LANDING_PAGE_URL;
const USER_AVATAR = process.env.REACT_APP_USER_AVATAR;
const TRANSLATION_URL = process.env.REACT_APP_TRANSLATION_URL;

const FOLLOW_UP_MESSAGE = "Is there anything else I can help you with?";

let phoneNumber = "";
let launcherImg = "";
const uuid = nanoid();
let messageId = "";
let isFirstMessage = true;
let message = "";
let links = [];
let messageButtonColor = "grey";
let messageTextColor = "black";
let followUpMessage = FOLLOW_UP_MESSAGE;
let userLanguage = "en";
let isAlreadyTranslated = false;
let isMessageButtonEnabledForAcc = false;
let isMultiligualEnabled = false;
let isPoweredByHidden = false;
let isPhotoUpload = false;

const languageMap = {
  af: "af-ZA",
  am: "am-ET",
  ar: "ar-SA",
  az: "az-AZ",
  bn: "bn-BD",
  bg: "bg-BG",
  ca: "ca-ES",
  cs: "cs-CZ",
  da: "da-DK",
  de: "de-DE",
  el: "el-GR",
  en: "en-US",
  et: "et-EE",
  fa: "fa-IR",
  fi: "fi-FI",
  fr: "fr-FR",
  gu: "gu-IN",
  he: "he-IL",
  hi: "hi-IN",
  hr: "hr-HR",
  hu: "hu-HU",
  id: "id-ID",
  is: "is-IS",
  it: "it-IT",
  ja: "ja-JP",
  ko: "ko-KR",
  lv: "lv-LV",
  lt: "lt-LT",
  ml: "ml-IN",
  mr: "mr-IN",
  mk: "mk-MK",
  ms: "ms-MY",
  ne: "ne-NP",
  nl: "nl-NL",
  no: "no-NO",
  pl: "pl-PL",
  pt: "pt-PT",
  ro: "ro-RO",
  ru: "ru-RU",
  si: "si-LK",
  sk: "sk-SK",
  sl: "sl-SI",
  es: "es-ES",
  sv: "sv-SE",
  ta: "ta-IN",
  te: "te-IN",
  th: "th-TH",
  tr: "tr-TR",
  uk: "uk-UA",
  ur: "ur-PK",
  vi: "vi-VN",
  zh: "zh-CN",
  zu: "zu-ZA",
};

const convertTextToHtml = (text) => {
  const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
  const urlPattern = /\b((http|https):\/\/[^\s/$.?#].[^\s]*)\b/g;
  // This is comes from the markdown syntax that is used in scout
  const boldPattern = /\*\*(.*?)\*\*/g; // **text** for bold
  const italicPattern = /\*(.*?)\*/g; // *text* for italic
  const headingPattern = /^(#{1,6})\s*(.*)$/gm; // Markdown-style headings
  const listItemPattern = /(?:^|\n)-\s+(.*)/g; // List items starting with `-`

  // Replace URLs with <a> tags
  let htmlText = text.replace(urlPattern, (url) => {
    // Ensure URLs without protocol default to http
    const prefix = url.match(/^(http|https):\/\//) ? "" : "http://";
    return `<a href="${prefix}${url}" target="_blank">${url.replace(
      /[^a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]+$/,
      ""
    )}</a>`;
  });

  // Replace emails with <a> tags
  htmlText = htmlText.replace(emailPattern, (email) => {
    return `<a href="mailto:${email}">${email}</a>`;
  });

  htmlText = htmlText.replace(boldPattern, "<b>$1</b>");

  htmlText = htmlText.replace(italicPattern, "<i>$1</i>");

  htmlText = htmlText.replace(headingPattern, (match, hashes, content) => {
    const level = hashes.length;
    return `<h${level}>${content}</h${level}>`;
  });

  htmlText = htmlText.replace(listItemPattern, "<li>$1</li>");

  // Wrap list items in <ul> if any <li> tags were found
  if (htmlText.includes("<li>")) {
    htmlText = htmlText.replace(/(?:<li>.*<\/li>)+/g, (match) => {
      return `<ul>${match}</ul>`;
    });
  }

  return htmlText;
};

const focusOnInput = () => {
  setTimeout(() => {
    const inputElement = document.querySelector(
      '.rcw-input[contenteditable="true"]'
    );
    if (inputElement) {
      inputElement.focus();
    }
  }, 200);
};

const handleFollowUpMessageTranslation = (message) => {
  const translateRequestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      text: message,
      targetLanguageCode: "en",
    }),
  };

  fetch(`${TRANSLATION_URL}`, translateRequestOptions)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      userLanguage =
        data.sourceLanguageCode || data.error?.sourceLanguageCode || "en";
      if (userLanguage !== "en") {
        const followupTranslateRequestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: followUpMessage,
            targetLanguageCode: userLanguage,
          }),
        };

        fetch(`${TRANSLATION_URL}`, followupTranslateRequestOptions)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (userLanguage !== "en") {
              followUpMessage = data.translatedText;
              isAlreadyTranslated = true;
            }
          })
          .catch((err) => {
            console.error("Translation Error: ", err);
          });
      } else {
        followUpMessage = FOLLOW_UP_MESSAGE;
        isAlreadyTranslated = true;
      }
    })
    .catch((err) => {
      console.error("Translation Error: ", err);
    });
};

const handleResponse = (response) => {
  return renderCustomComponent(
    () => (
      <CustomResponseMessageComponent
        key={response.messageId}
        message={response.message}
        links={response.links}
        buttons={response.options}
        messageId={response.messageId}
      />
    ),
    [],
    true
  );
};

function onParse(event) {
  if (event.type === "event") {
    if (event.data === "[DONE]") {
      isFirstMessage = true;
      deleteMessages(1);
      handleResponse({
        message,
        links,
        messageId,
        options: [],
      });
      message = "";
      links = [];
      handleResponse({ message: followUpMessage });
    }
    if (event.data && event.data !== "[DONE]") {
      message = JSON.parse(event.data)?.output?.output;
      links = JSON.parse(event.data)?.output?.metadata?.sources;
      if (message) {
        const output = {
          messageId,
          message,
          links: [],
          options: [],
        };
        if (isFirstMessage) {
          isFirstMessage = false;
          handleResponse(output);
        } else {
          const messageElement = document.getElementById(messageId);
          if (messageElement) {
            messageElement.innerHTML = output.message;
            messageElement.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }
      }
    }
  }
}

// Engine Lex request
async function handleLexRequest(requestOptions) {
  try {
    const response = await fetch(`${ENGINE_URL}/web`, requestOptions);

    if (!response.ok) {
      const contentType = response.headers.get("Content-Type");
      const errorMessage =
        contentType && contentType.includes("application/json")
          ? (await response.json()).message ||
            `Something went wrong. Please try again later.`
          : `Something went wrong. Please try again later.`;

      if (!isMessageButtonEnabledForAcc) {
        const timer = setTimeout(() => {
          addMessageComponent(timer, true, "onBotError");
        }, 1000);
      }

      handleResponse({ message: errorMessage });
      throw new Error(errorMessage);
    }

    messageId = nanoid();
    const contentType = response.headers.get("Content-Type");

    if (contentType.startsWith("text/event-stream")) {
      const parser = createParser(onParse);
      const reader = response.body.getReader();

      await push(reader, parser).finally(() => {
        isAlreadyTranslated = false;
        console.log("Post-processing after push completes");
        toggleMsgLoader();
        toggleInputDisabled(false);
        toggleSendButton();
        enableDisableMessageButton(true);
        focusOnInput();
      });
    } else {
      const data = await response.json();
      let options = [];

      if (data.sessionState) {
        const {
          sessionState: {
            status,
            response = null,
            sources = [],
            options: responseOptions = [],
            intent,
          },
        } = data;

        options = responseOptions;
        handleResponse({ message: response, sources, options });

        if (status === "intentFulfilled") {
          enableDisableMessageButton(true);
          isAlreadyTranslated = false;
          if (
            intent?.name !== "hellointent" &&
            intent?.name !== "thank_you_intent"
          ) {
            handleResponse({ message: followUpMessage });
          }
        } else if (status === "intentFailed") {
          enableDisableMessageButton(true);
          isAlreadyTranslated = false;
          handleResponse({ message: followUpMessage });
        } else {
          enableDisableMessageButton(false);
        }
      } else if (data.message) {
        isAlreadyTranslated = false;
        enableDisableMessageButton(data.isValid !== false);
        handleResponse({ message: data.message });
      } else {
        handleResponse({
          message:
            data.message || "Something went wrong. Please try again later.",
        });
        enableDisableMessageButton(true);
      }

      toggleMsgLoader();
      if (options.length <= 0) {
        toggleInputDisabled(true);
      }
      toggleSendButton();
      focusOnInput();
    }
  } catch (err) {
    console.error("Fetch Error:", err);
    toggleMsgLoader();
    toggleInputDisabled(false);
    toggleSendButton();
    enableDisableMessageButton(true);
    focusOnInput();
  }
}

const handleNewUserMessage = async (message, phoneNumber, type = "text") => {
  if (!message) {
    return;
  }

  if (!isAlreadyTranslated && isMultiligualEnabled) {
    handleFollowUpMessageTranslation(message);
  }

  toggleMsgLoader();
  if (type === "text") {
    toggleInputDisabled(true);
  }
  enableDisableMessageButton(false);
  toggleSendButton();
  let requestOptions = {
    method: "POST",
    headers: {
      aud: "webchat",
      Authorization: `Bearer ${WEBCHAT_TOKEN}`,
      "Content-Type": "application/json",
    },
  };

  if (message instanceof File) {
    const formData = new FormData();
    formData.append("files", message);
    delete requestOptions.headers["Content-Type"];
    requestOptions = {
      ...requestOptions,
      data: formData,
    };
    try {
      const fileResponse = await axios(
        `${ENGINE_URL}/photo-upload`,
        requestOptions
      );
      if (fileResponse) {
        const fileURL = fileResponse.data.fileURL;
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions = {
          ...requestOptions,
          body: JSON.stringify({
            to: phoneNumber,
            from: uuid,
            body: fileURL,
            NumMedia: 1,
          }),
        };
        await handleLexRequest(requestOptions);
      }
    } catch (error) {
      const contentType = error.response?.headers["content-type"];

      if (contentType && contentType.includes("application/json")) {
        if (!isMessageButtonEnabledForAcc) {
          const timer = setTimeout(function () {
            addMessageComponent(timer, true, "onBotError");
          }, 1000);
        }
        const errorMessage = "Something went wrong. Please try again later.";
        handleResponse({ message: errorMessage });
      } else {
        const errorMessage = "Something went wrong. Please try again later.";
        handleResponse({ message: errorMessage });
      }

      console.error("Axios Error:", error);
      toggleMsgLoader();
      toggleInputDisabled(false);
      toggleSendButton();
      enableDisableMessageButton(true);
      focusOnInput();
    }
  } else {
    requestOptions = {
      ...requestOptions,
      body: JSON.stringify({
        to: phoneNumber,
        from: uuid,
        body: message,
      }),
    };
    // Engine Lex request
    await handleLexRequest(requestOptions);
  }
};

function push(reader, parser) {
  return new Promise((resolve, reject) => {
    reader
      .read()
      .then(({ done, value }) => {
        if (done) {
          resolve(); // Resolve the promise when done
          return;
        }
        const decodedValue = new TextDecoder().decode(value);
        parser.feed(decodedValue);
        push(reader, parser).then(resolve).catch(reject); // Recursively call push and resolve/reject based on the recursive calls
      })
      .catch(reject); // Reject the promise on error
  });
}

const handleQuickButtonClicked = (button, setDisableButtons) => {
  addUserMessage(button.charAt(0).toUpperCase() + button.slice(1));
  handleNewUserMessage(button, phoneNumber, "option");
  setDisableButtons(true);
};

const LinkPreview = ({ link }) => {
  const { title: rawLink, url } = link;
  const title = rawLink.trim();
  return (
    <div id={url}>
      <article className="article">
        <a href={url} target="_blank" rel="noreferrer">
          <section className="article__content">
            <div className="article__description">
              <h3 className="article__title" title={title}>
                {title}
              </h3>
            </div>
          </section>
        </a>
      </article>
    </div>
  );
};

const SpeakButton = ({ isSpeaking, handleToggleSpeak, message }) => {
  return (
    <button className="speak__icon" onClick={() => handleToggleSpeak(message)}>
      {isSpeaking ? (
        <img src={SPEAKER} alt="speaker" width="20px" height="20px" />
      ) : (
        <img src={SPEAKER_MUTE} alt="speaker" width="20px" height="20px" />
      )}
    </button>
  );
};

const SpeakOptionsButton = ({
  isSpeakingOption,
  handleToggleSpeak,
  message,
}) => {
  return (
    <button
      className="rcw_speak__icon"
      onClick={() => handleToggleSpeak(message)}
    >
      {isSpeakingOption ? (
        <img src={SPEAKER} alt="speaker" width="20px" height="20px" />
      ) : (
        <img src={SPEAKER_MUTE} alt="speaker" width="20px" height="20px" />
      )}
    </button>
  );
};

const CustomResponseMessageComponent = React.memo(
  ({ message, links, buttons, messageId }) => {
    const [disableButtons, setDisableButtons] = useState(false);
    const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [isSpeakingOptions, setIsSpeakingOptions] = useState([]);
    const utteranceRef = useRef(null);

    const formatTime = () => {
      return parse(
        moment(parseInt(Date.now()))
          .format("hh:mm A")
          .replace(/\b(st|nd|rd|th)\b/g, "<sup>$2</sup>")
      );
    };

    const handleSpeak = useCallback((message, type, buttonIndex) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(message, "text/html");
      const text = doc.body.textContent;
      const speech = new SpeechSynthesisUtterance(text);
      // const langCode = franc(text);
      const langCode = userLanguage;
      const lang = languageMap[langCode] || "en-US"; // Default to English if language is not recognized
      speech.lang = lang;
      speech.rate = 0.8;
      window.speechSynthesis.cancel();
      speech.onend = () => handleStop(buttonIndex);
      window.speechSynthesis.speak(speech);
      utteranceRef.current = speech;
      if (type === "option") {
        setIsSpeakingOptions((prev) => {
          const newState = [...prev];
          newState[buttonIndex] = true;
          return newState;
        });
      } else {
        setIsSpeaking(true);
      }
    }, []);

    const handleStop = useCallback((buttonIndex) => {
      window.speechSynthesis.cancel();
      if (utteranceRef.current) {
        utteranceRef.current.onend = null;
      }
      setIsSpeaking(false);
      setIsSpeakingOptions((prev) => {
        const newState = [...prev];
        if (buttonIndex !== undefined) {
          newState[buttonIndex] = false;
        } else {
          newState.fill(false);
        }
        return newState;
      });
    }, []);

    const handleToggleSpeak = useCallback(
      (message) => {
        if (isSpeaking) {
          handleStop();
        } else {
          handleSpeak(message, "message");
        }
      },
      [isSpeaking, handleSpeak, handleStop]
    );

    const handleToggleSpeakOption = useCallback(
      (message, buttonIndex) => {
        if (isSpeakingOptions[buttonIndex]) {
          handleStop(buttonIndex);
        } else {
          handleSpeak(message, "option", buttonIndex);
        }
      },
      [isSpeakingOptions, handleSpeak, handleStop]
    );

    useEffect(() => {
      return () => {
        handleStop();
      };
    }, [handleStop]);
    return (
      <div className="container" key={messageId}>
        <div className="rcw-response">
          <div className="rcw-message-text" id={messageId}>
            {message &&
              parse(convertTextToHtml(message, { target: "_blank" }, true))}
            {links?.map((link, index) => (
              <LinkPreview key={index} link={link} />
            ))}
            <SpeakButton
              isSpeaking={isSpeaking}
              handleToggleSpeak={handleToggleSpeak}
              message={message}
            />
          </div>
          {buttons?.length > 0 && (
            <div className="quick-buttons-container rcw-quick-buttons-container">
              <ul className="quick-buttons rcw-quick-buttons-ul">
                {buttons.map((button, buttonIndex) => (
                  <li
                    key={buttonIndex}
                    className="quick-list-button rcw-quick-button-li"
                  >
                    <button
                      className={`quick-button rcw-quick-button-btn ${
                        disableButtons && selectedButtonIndex !== buttonIndex
                          ? "rcw-quick-button-not-selected"
                          : `${
                              selectedButtonIndex === buttonIndex
                                ? "rcw-quick-button-selected"
                                : ""
                            }`
                      }`}
                      disabled={disableButtons}
                      onClick={() => {
                        handleQuickButtonClicked(button, setDisableButtons);
                        setSelectedButtonIndex(buttonIndex);
                      }}
                    >
                      <span className="rcw-quick-button-text">{button}</span>
                    </button>
                    <SpeakOptionsButton
                      isSpeakingOption={isSpeakingOptions[buttonIndex]}
                      handleToggleSpeak={() =>
                        handleToggleSpeakOption(button, buttonIndex)
                      }
                      message={button}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
          <span className="rcw-timestamp">Received {formatTime()}</span>
        </div>
      </div>
    );
  }
);

const addCustomMessage = () => {
  addUserMessage("Message");
  handleNewUserMessage("Message", phoneNumber);
};

const MessageButtonComponent = ({ enableMessageButton, type }) => {
  return (
    <div className="custom-message-button">
      {enableMessageButton === true && <button>Message</button>}
      {type === "onBotLoad" && !isPoweredByHidden && (
        <a
          className="powered-by"
          href={CITIBOT_LANDING_PAGE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="p-span">Powered by</span>
          <span className="c-span">CITIBOT</span>
        </a>
      )}
    </div>
  );
};

const enableDisableMessageButton = (toggleState) => {
  const messageButton = document.querySelector(".custom-message-button button");
  if (messageButton) {
    if (toggleState) {
      messageButton.style.backgroundColor = messageButtonColor;
      messageButton.style.color = messageTextColor;
      messageButton.addEventListener("click", addCustomMessage);
      messageButton.classList.add("hover-effect");
    } else {
      messageButton.style.backgroundColor = "grey";
      messageButton.style.color = "white";
      messageButton.removeEventListener("click", addCustomMessage);
      messageButton.classList.remove("hover-effect");
    }
  }
};

const fillSendButtonColor = () => {
  // Change the color of send button according to color of the loaded bot
  const imgElement = document.querySelector(".rcw-send-icon");
  // Extract the data URL
  const dataUrl = imgElement.src;
  // Decode the base64 encoded SVG
  const svgContent = atob(dataUrl.split(",")[1]);
  // Create a temporary div to hold the SVG HTML text
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = svgContent;
  // Grab the SVG element
  const svgElement = tempDiv.querySelector("svg");

  // Modify the SVG fill color
  if (svgElement.querySelector("polygon")) {
    svgElement.querySelector("polygon").style.fill = messageButtonColor;
  }
  svgElement.setAttribute("class", "rcw-send-icon");
  // Replace the img element with the new inline SVG
  imgElement.parentNode.replaceChild(svgElement, imgElement);
};

const toggleSendButton = () => {
  const svgElementDisabled = document.querySelector(".rcw-send-icon-disabled");
  const svgElementEnabled = document.querySelector(".rcw-send-icon");
  if (svgElementDisabled) {
    if (svgElementDisabled.querySelector("polygon")) {
      svgElementDisabled.querySelector("polygon").style.fill =
        messageButtonColor;
    }
    svgElementDisabled.setAttribute("class", "rcw-send-icon");
    return;
  }
  if (svgElementEnabled) {
    // Modify the SVG fill color
    if (svgElementEnabled.querySelector("polygon")) {
      svgElementEnabled.querySelector("polygon").style.fill = "grey";
    }
    svgElementEnabled.setAttribute("class", "rcw-send-icon-disabled");
  }
};

const addMessageComponent = (
  timer,
  enableMessageButton,
  type = "onBotLoad"
) => {
  const sendDiv = document.querySelector(".rcw-sender");
  const messageButton = document.querySelector(".custom-message-button button");
  if (sendDiv && !messageButton) {
    // Element has loaded
    sendDiv.insertAdjacentHTML(
      "afterend",
      ReactDOMServer.renderToStaticMarkup(
        <MessageButtonComponent
          enableMessageButton={enableMessageButton}
          type={type}
        />
      )
    );
    clearTimeout(timer);
    if (type === "onBotLoad") {
      fillSendButtonColor();
    }
    if (enableMessageButton) {
      enableDisableMessageButton(true);
    }
  }
};

function App(props) {
  const [isChatOpen, setIsChatOpen] = useState(false); // Track chatbot open status
  const [isWelcomeMessageSet, setIsWelcomeMessageSet] = useState(false);
  const [titleAvatar, setTitleAvatar] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [customerLogo, setCustomerLogo] = useState("");
  const [launcherOpenImg, setLauncherOpenImg] = useState("");
  const [launcherCloseImg, setLauncherCloseImg] = useState("");
  const [launcherImgLoaded, setLauncherImgLoaded] = useState(false);
  const [enableMessageButton, setEnableMessageButton] = useState(false);
  const [loading, setLoading] = useState(true);

  const searchParams = new URLSearchParams(window.location.search);
  let accountId = searchParams.get("accountId");
  if (!accountId) {
    accountId = props.accountId;
  }

  useEffect(() => {
    fetch(`${API_URL}/account/${accountId}/chatConfig`, {
      headers: {
        "Content-Type": "application/json",
        aud: "budibase",
        Authorization: `Bearer ${BUDIBASE_TOKEN}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        phoneNumber = data._id;
        isMessageButtonEnabledForAcc = data.enableMessageButton;
        isMultiligualEnabled = data.multilingual ?? false;
        isPoweredByHidden = data.isPoweredByHidden ?? false;
        setLauncherOpenImg(data.chatOpenningImgUrl);
        setLauncherCloseImg(data.chatCloseImgUrl);
        setTitleAvatar(data.botProfileImgUrl);
        setTitle(data.chatWidgetTitle);
        setSubtitle(data.chatWidgetSubTitle);
        setCustomerLogo(data.logoImgUrl);
        setEnableMessageButton(data.enableMessageButton);
        setLauncherImgLoaded(true);
        isPhotoUpload = data.isPhotoUpload ?? false;
        setLoading(false);
        document.documentElement.style.setProperty(
          "--header-background-color",
          data.headerBackgroundColor
        );
        document.documentElement.style.setProperty(
          "--header-text-color",
          data.headerTextColor
        );
        document.documentElement.style.setProperty(
          "--message-background-color",
          data.messageBackgroundColor
        );
        document.documentElement.style.setProperty(
          "--message-text-color",
          data.messageTextColor
        );

        messageButtonColor = data.headerBackgroundColor;
        messageTextColor = data.headerTextColor;

        if (!isWelcomeMessageSet) {
          renderCustomComponent(
            () => (
              <CustomResponseMessageComponent message={data.greetingCopy} />
            ),
            [],
            true
          );
          setIsWelcomeMessageSet(true);
        }
        if (!loading && !isPhotoUpload) {
          const timeoutId = setTimeout(() => {
            const uploadIcon = document.querySelector(".rcw-upload-icon");
            if (uploadIcon) uploadIcon.style.display = "none";
          }, 2000);

          return () => clearTimeout(timeoutId);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        renderCustomComponent(
          () => (
            <CustomResponseMessageComponent message="Sorry, something went wrong. Please try again later." />
          ),
          [],
          true
        );
      });
  }, [isChatOpen, isWelcomeMessageSet, accountId, loading, isPhotoUpload]);

  const handlePaste = (event) => {
    event.preventDefault();
    const text = (event.clipboardData || window.clipboardData).getData("text");
    document.execCommand("insertText", false, text);
  };

  const handleToggle = (toggleState) => {
    setIsChatOpen(toggleState);
    launcherImg = toggleState ? launcherCloseImg : launcherOpenImg;
    if (toggleState) {
      const timer = setTimeout(function () {
        addMessageComponent(timer, enableMessageButton);
      }, 1000);

      setTimeout(function () {
        const contentEditableDiv = document.querySelector(".rcw-input");
        // remove any previous listener
        contentEditableDiv.removeEventListener("paste", handlePaste);
        if (contentEditableDiv) {
          contentEditableDiv.addEventListener("paste", handlePaste);
        }
      }, 300);
    }
  };

  const getCustomLauncher = (handleToggle) => {
    return (
      <>
        {launcherImgLoaded ? (
          <button className="launcherBtn" onClick={handleToggle}>
            {" "}
            <img
              className="launcherImg"
              src={launcherImg || launcherOpenImg}
              alt="Open"
            ></img>
          </button>
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <div className="App">
      <Widget
        handleNewUserMessage={(message) =>
          handleNewUserMessage(message, phoneNumber)
        }
        profileAvatar={customerLogo || logo}
        profileClientAvatar={USER_AVATAR}
        title={title || "Citibot"}
        subtitle={subtitle || ""}
        titleAvatar={titleAvatar}
        resizable={false}
        handleToggle={handleToggle}
        launcher={(handleToggle) => getCustomLauncher(handleToggle)}
      />
    </div>
  );
}

export default App;
